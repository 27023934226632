import React from 'react';
import { Helmet } from "react-helmet";

const SEO = ({ title, description, name, type, keywords }) => {
  return (

    <Helmet>
      { /* Standard metadata tags */ }
        <title>NCL - {title}</title>
        <meta name='description' content={description} />
        <meta name="keywords" content={keywords} />
      { /* End standard metadata tags */ }
      
      { /* Facebook tags */ }
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      { /* End Facebook tags */ }

      { /* Twitter tags */ }
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="google-site-verification" content="DtowAz3g68nLS3vNa28o3zlOxwNEI6fERIj2uPzwPL0" />
      { /* End Twitter tags */ }
    </Helmet>

  );
};

export default SEO;
